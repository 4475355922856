import React from "react"
import * as AlertBarStyles from "./AlertBar.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignsPost } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

export default function AlertBar() {
    return (
        <div className={AlertBarStyles.wrapper}>
            <div className={AlertBarStyles.content}>
                <p><FontAwesomeIcon icon={faSignsPost}/><Link to="/blog/we-have-moved/"> The practice has moved location. Find&nbsp;out more here.</Link></p>
            </div>
        </div>
    )
}