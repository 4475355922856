import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as HeaderStyles from './Header.module.css'
import AlertBar from '../../AlertBar/AlertBar'

function Down({ mobile }) {
    return (
        <>
            {mobile ? <span className={HeaderStyles.down}> &#9662;</span> : <span className={HeaderStyles.downDesktop}> &#9662;</span>}
        </>
    )
    
}

function Header() {
    const [navOpen, setNavOpen] = useState(false)
    const [servicesSubNavOpen, setServicesSubNavOpen] = useState(false)
    const [treatmentsSubNavOpen, setTreatmentsSubNavOpen] = useState(false)

    return (
        <>
        <header>
            <div className={HeaderStyles.navContainer}>
                <Link className={HeaderStyles.logoLink} to="/">
                    <StaticImage
                        className={HeaderStyles.logo}
                        src="../../../images/boc-logo-horizontal-colour.png"
                        alt="Brixworth Osteopathic Clinic logo"
                        width={200}
                        placeholder="blurred"
                    />
                </Link>
                <div className={HeaderStyles.toggle} navopen={navOpen} onClick={() => setNavOpen(!navOpen)} onKeyPress={() => setNavOpen(!navOpen)} aria-label="Main menu" role="button" tabIndex={0}>
                    <div className={navOpen ? `${HeaderStyles.hamburgerOpen}` : `${HeaderStyles.hamburger}`}></div>
                </div>
                <nav className={navOpen ? `${HeaderStyles.nav} ${HeaderStyles.mobileNav}` : `${HeaderStyles.nav}`} >
                    <Link activeStyle={{color: 'var(--secondary-700'}} to="/services/" onMouseOver={() => setServicesSubNavOpen(true)} onMouseOut={() => setServicesSubNavOpen(false)}>Services<Down mobile={true} />
                        <div className={servicesSubNavOpen ? `${HeaderStyles.servicesSubNav}` : `${HeaderStyles.servicesSubNav} ${HeaderStyles.subNavClosed}`}>
                            <Link activeStyle={{color: 'var(--secondary-700)'}} to="/services/osteopathy/">Osteopathy</Link>
                            <Link activeStyle={{color: 'var(--secondary-700)'}} to="/services/sports-remedial-massage/">Sports & Remedial Massage</Link>
                            <Link activeStyle={{color: 'var(--secondary-700)'}} to="/services/cranial-osteopathy/">Cranial Osteopathy</Link>
                            <Link activeStyle={{color: 'var(--secondary-700)'}} to="/services/infant-feeding-coaching/">Infant Feeding Coaching</Link>
                        </div>
                    </Link>
                    <Link activeStyle={{color: 'var(--secondary-700'}} to="/about/">About</Link>
                    <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/" onMouseOver={() => setTreatmentsSubNavOpen(true)} onMouseOut={() => setTreatmentsSubNavOpen(false)}>What we treat<Down mobile={false} />
                        <div className={treatmentsSubNavOpen ? `${HeaderStyles.treatmentsSubNav}` : `${HeaderStyles.treatmentsSubNav} ${HeaderStyles.subNavClosed}`}>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/discomfort-in-advancing-years/">Discomfort in advancing years</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/pregnancy-related-discomfort/">Pregnancy related discomfort</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/postural-strain-at-work-and-driving/">Postural strain at work & driving</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/sports-injury/">Sports Injury</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/neck-and-head-pain/">Neck and head pain</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/shoulder-pain/">Shoulder Pain</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/knee-pain/">Sciatica and other nerve related pain</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/back-pain/">Back pain</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/pain-in-childhood-and-adolescence/">Pain in childhood and adolescence</Link>
                            <Link activeStyle={{color: 'var(--secondary-700'}} to="/conditions-we-treat/ankle-and-foot-pain/">Ankle and foot pain</Link>
                        </div>
                    </Link>
                    <Link activeStyle={{color: 'var(--secondary-700'}} to="/faq/">FAQ</Link>
                    <Link activeStyle={{color: 'var(--secondary-700'}} to="/blog/">Blog</Link>
                    <Link activeStyle={{color: 'var(--secondary-700'}} to="/contact/">Contact</Link>
                </nav>
            </div>
        </header>
        <AlertBar />
        </>
    )
}

export default Header