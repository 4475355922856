import * as React from 'react'
import './layout.css'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/400-italic.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/900.css'
import { Helmet } from 'react-helmet'

function Layout({ children }) {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
            </Helmet>
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}

export default Layout