import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import * as FooterStyles from './Footer.module.css'

function Footer() {
    const date = new Date()
    const year = date.getFullYear()

    return (
        <footer className={FooterStyles.footer}>
            <div className={FooterStyles.main}>
                <div className={FooterStyles.linksSection}>
                    <h4>Useful links</h4>
                    <ul className={FooterStyles.links}>
                        <li><Link to="/services/">Services</Link></li>
                        <li><Link to="/about/">About</Link></li>
                        <li><Link to="/conditions-we-treat/">What we treat</Link></li>
                        <li><Link to="/faq/">FAQ</Link></li>
                        <li><Link to="/blog/">Blog</Link></li>
                        <li><Link to="/contact/">Contact</Link></li>
                        <li><Link to="/privacy-policy/">Privacy policy</Link></li>
                    </ul>
                </div>
                <div className={FooterStyles.rightCol}>
                    <div className={FooterStyles.social}>
                        <a href="https://facebook.com/brixworthosteo/" rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faFacebookSquare} />
                        </a>
                        <a href="https://twitter.com/brixworthosteo/" rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faTwitterSquare} />
                        </a>
                    </div>
                    <div className={FooterStyles.miscellaneous}>
                        <StaticImage
                            src="../../../images/GOC.webp"
                            alt="General Osteopathic Council registration mark"
                            width={150}
                            placeholder="blurred"
                        />
                    </div>
                </div>
            </div>
            <div className={FooterStyles.bottom}>
                <div className={FooterStyles.bottomContainer}>
                    <p>{year} © - Brixworth Osteopathic Clinic</p>
                    <p>Website by <a href="https://goddard.digital">Goddard Digital</a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer